import {useSbCalls} from '../../../lib/sendbird-calls';
import {useHistory, useLocation, useRouteMatch} from 'react-router-dom';
import React, {useEffect, useMemo, useState} from 'react';
import CallView from '../../views/CallView';
import styled from 'styled-components';
import {media} from '../../../utils';
import Screen from '../../templates/Screen/Screen';
import * as mixins from '../../../styles/mixins';
import Overlay from '../../atoms/Overlay';
import storage from '../../../lib/storage';
import {DirectCallOption} from 'sendbird-calls';

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  padding-bottom: 55px;
  background-color: black;
  ${media.main} {
    padding-bottom: 0;
  }
`;

const Contents = styled(Screen)`
  ${mixins.flexCenter};
  flex-direction: column;
  height: calc(100% - 80px - 57px);
  background-color: black;
  ${media.main} {
    height: calc(100% - 48px - 56px);
  }
`;

function getCallOption(callOption?: DirectCallOption) {
    return {
        localMediaView: undefined,
        remoteMediaView: undefined,
        videoEnabled: true,
        audioEnabled: true,
        ...callOption,
    };
}

const DirectCallMain: React.FC<any> = ({children}) => {
    const {isAuthenticated, calls} = useSbCalls();
    const history = useHistory();
    const location = useLocation();
    const {url} = useRouteMatch();
    const sbCall = useSbCalls();
    const [userId, setUserId] = useState('')
    const {clearCalls} = useSbCalls();

    useEffect(() => {
        if (location.pathname === `${url}/login`) {
            if (isAuthenticated) {
                history.replace('/main/dial');
            }
        }
    }, [isAuthenticated, location.pathname]);

    useEffect(() => {
        (async () => {
            const currentCallData = await storage.getItem('sbCalls')
            if (currentCallData) {
                setUserId(currentCallData.pacientID)
            }
        })()
    }, [isAuthenticated])

    const dial = (isVideoCall: boolean) => {
        sbCall.dial({userId, isVideoCall, callOption: getCallOption({})});
    };

    useEffect(() => {
        if (userId) {
            dial(true)
        }
    }, [isAuthenticated, userId])

    useEffect(() => {
        if (location.pathname === `${url}/login`) {
            if (isAuthenticated) {
                history.replace('/main/dial');
            }
        }
    }, [isAuthenticated, location.pathname]);

    const onCall = useMemo(() => {
        return calls.find(call => call.isOngoing)
    }, [calls])

    useEffect(() => {
        if (onCall?.callState === 'ended') {
            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(JSON.stringify({action: "End Call"}));
            }
            onCall.end()
            clearCalls()
        }
    }, [onCall])

    return (
        <Wrapper>
            {!!calls.length && <CallView call={calls[calls.length - 1]}/>}
            <Contents>
                {onCall &&
          <Overlay>
              <CallView call={onCall} userId={userId}/>
          </Overlay>
                }
            </Contents>
        </Wrapper>
    );
};

export default DirectCallMain;
