import {ErrorMessage} from 'components/atoms/Toast';
import React, {useEffect} from 'react';
import {useLocation} from 'react-use';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';
import Input from 'components/atoms/Input';
import {useSbCalls} from 'lib/sendbird-calls';
import {SoundType} from 'sendbird-calls';
import type {AuthOption} from 'sendbird-calls';
import {toast} from 'react-toastify';
import storage from 'lib/storage';
import BounceLoader from 'react-spinners/BounceLoader';
import LandingPage from '../../pages/LandingPage';

const FormContainer = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  background-color: black;
  justify-content: center;
  align-items: center;

  ${Input} {
    margin-bottom: 16px;
  }
`;

const url = new URL(window.location.href)

const LoginForm = () => {
    const userParamsData = url.searchParams.get('q');
    const userParams = userParamsData ? JSON.parse(atob(userParamsData)) : ''
    const sbCalls = useSbCalls();
    const history = useHistory();
    const query = new URLSearchParams(useLocation().search);

    const APP_ID = userParams.app_id;
    const USER_ID = userParams.user_id;
    const ACCESS_TOKEN = userParams.access_token;
    const ROOM_ID = userParams.user_id + userParams.pacient_id;

    const login = () => {
        const option: AuthOption = {userId: USER_ID, accessToken: ACCESS_TOKEN};
        sbCalls.init(APP_ID, USER_ID);
        sbCalls.addDirectCallSound(SoundType.DIALING, '/sounds/Dialing.mp3');
        sbCalls.addDirectCallSound(SoundType.RINGING, '/sounds/Ringing.mp3');
        sbCalls.addDirectCallSound(SoundType.RECONNECTING, '/sounds/Reconnecting.mp3');
        sbCalls.addDirectCallSound(SoundType.RECONNECTED, '/sounds/Reconnected.mp3');
        return sbCalls.auth(option)
            .then(() => {
                storage.setItem('sbCalls', {
                    appId: userParams.app_id,
                    userID: userParams.user_id,
                    pacientID: userParams.pacient_id,
                    accessToken: userParams.access_token
                });
                setTimeout(() =>{
                    history.push(`${query.get('referrer') ? query.get('referrer') + `?room_id=${ROOM_ID}` : '/'}`)
                }
                ,3000)
            })
            .catch(() => {
                toast.error(<ErrorMessage message={`Check entered information and try again.`}/>, {autoClose: 2000});
            })
    }

    useEffect(() => {
        (APP_ID && USER_ID && ACCESS_TOKEN) && login();
    }, [])

    return (
        <FormContainer>
            {APP_ID && USER_ID ?
                <BounceLoader
                    color={'white'}
                    loading={true}
                    size={150}/> :
                <LandingPage/>
            }
        </FormContainer>
    );
};

export default LoginForm;
