import React from 'react';
import styled from 'styled-components';
import * as mixins from 'styles/mixins';
import {media} from 'utils';
import LoginForm from 'components/organisms/LoginForm';
import Screen from 'components/templates/Screen';

const Wrapper = styled(Screen)`
  width: 100vw;
  height: 100vh;
  ${mixins.flexCenter};
  color: rgb(33, 34, 66);
  background-color: rgb(255, 255, 255);
  ${media.main} {
    background-color: black;
  }
`;

const LoginPage = () => {
    return (
        <Wrapper>
            <LoginForm/>
        </Wrapper>
    )
}
export default LoginPage;
