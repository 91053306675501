import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import './styles/toast.override.css';
import LoginPage from 'components/pages/LoginPage';
import DirectCallApp from './DirectCallApp';
import LandingPage from './components/pages/LandingPage';

function App() {

    return (
        <Router>
            <Switch>
                <Route exact path='/' component={LandingPage} />
                <Route path='/direct-call' component={DirectCallApp} />
                <Route path='/login' component={LoginPage} />
            </Switch>
        </Router>
    );
}

export default App;
