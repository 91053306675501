import React, {useCallback, useEffect, useState} from 'react';
import styled, {css} from 'styled-components';

import {
    AudioCallAcceptButton,
    VideoCallAcceptButton,
    MuteButton,
    UnmuteButton,
    StartVideoButton,
    StopVideoButton,
    EndButton,
    ChatButton,
    ResizeButtonMedium,
    ResizeButtonSmall,
    MuteButtonOpacity,
    UnmuteButtonOpacity,
    StopVideoButtonOpacity,
    StartVideoButtonOpacity,
} from 'components/atoms/CallButtons';
import Screen from 'components/templates/Screen';
import {useSbCalls} from 'lib/sendbird-calls';
import type {StatefulDirectCall} from 'lib/sendbird-calls';
import * as mixins from 'styles/mixins';
import {isSafari, media} from 'utils';

const Wrapper = styled(Screen)`
  ${mixins.flexCenter};
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1; // TODO: Temporary z-index because of menu dropdown coming upfront
  color: white;
  background-color: var(--navy-900);
`;

const Foreground = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  align-items: flex-end;
`;

const ButtonsContainerRing = styled.div`
  display: flex;
  justify-content: space-around;
  width: 350px;
  align-items: center;
`;

const CallButtonsContainer = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Background = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  border-radius: 8px;

  ${media.main} {
    border-radius: 0;
  }
`;

type MediaViewSize = 'hidden' | 'small' | 'full';
const getVideoStyleFromSize = (size: MediaViewSize) => {
    switch (size) {
        case 'hidden':
            return css`
        opacity: 0;
        transition: all 0.5s;
        width: 0;
        height: 0;
      `;
        case 'small':
            return css`
        position: absolute;
        top: 50px;
        right: 20px;
        width: 130px;
        height: 180px;
        border-radius: 20px;
        background-color: var(--navy-300);
      `;
        case 'full':
            return css`
        position: absolute;
        width: 100%;
        height: 100%;
        transition: all 1s;
      `;
        default:
            return '';
    }
};

const VideoViewDiv = styled.div<{ size: MediaViewSize }>`
  position: absolute;
  overflow: hidden;
  ${props => getVideoStyleFromSize(props.size)}
`;

const VideoView = styled.video`
  left: 50%;
  height: 100%;
  position: absolute;
  transform: translate(-50%);
`;

const MobileBackDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 20px;
  left: -15px;
  width: 60%;
  padding: 7px;
`;

const Logo = styled.img`
  display: block;
  width: 25px;
  height: 25px;
`;

const ControlButtonText = styled.text`
  padding-left: 7px;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
`;

const CallerNameDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  color: #212242;
  font-size: 18px;
  font-weight: 600;
  padding: 10px;
  border-radius: 5px;
  width: 85%;
  -webkit-box-shadow: 0px 16px 31px -10px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0px 16px 31px -10px rgba(0, 0, 0, 1);
  box-shadow: 0px 16px 31px -10px rgba(0, 0, 0, 1);
  opacity: 0.8;
`;

const Controls = styled.div`
  ${mixins.flexColumn};

  ${media.main} {
    position: relative;
  }
`;

interface CallViewProps {
  call: StatefulDirectCall,
  userId?: any
}

const CallView: React.FC<CallViewProps> = ({call, userId}) => {
    const {clearCalls} = useSbCalls();
    const [callTime, setCallTime] = useState(0);
    const [mobileWebViewStatus, setMobileWebViewStatus] = useState('full');
    const {
        callState,
        isLocalAudioEnabled,
        isLocalVideoEnabled,
        localMediaView,
        remoteMediaView,
    } = call;

    const difference = +new Date() - callTime;

    const localMediaViewRef = useCallback(node => {
        call.setLocalMediaView(node);
    }, []);

    const remoteMediaViewRef = useCallback(node => {
        call.setRemoteMediaView(node);
    }, []);

    const accept = useCallback(() => {
        call.accept({
            callOption: {
                localMediaView,
                remoteMediaView,
                audioEnabled: true,
                videoEnabled: true,
            },
        });
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({action: "Call Accepted"}));
        }
    }, [localMediaView, remoteMediaView]);

    const calculateTimeLeft = () => {
        let timeLeft = {
            hours: 0,
            minutes: 0,
            seconds: 0
        };

        if (difference > 0) {
            timeLeft = {
                hours: Math.floor(difference / (1000 * 60 * 60)),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    let localMediaViewSize: MediaViewSize;
    let remoteMediaViewSize: MediaViewSize;

    switch (callState) {
        case 'dialing':
        case 'ringing':
            localMediaViewSize = 'full';
            remoteMediaViewSize = 'hidden';
            break;
        case 'connected':
        case 'reconnecting':
            localMediaViewSize = 'small';
            remoteMediaViewSize = 'full';
            break;
        case 'ended':
        default:
            localMediaViewSize = 'hidden';
            remoteMediaViewSize = 'hidden';
    }

    if (!userId) {
        switch (mobileWebViewStatus) {
            case ('medium'):
                localMediaViewSize = 'hidden';
                remoteMediaViewSize = 'full';
                break;
            case ('small'):
                localMediaViewSize = 'hidden';
                remoteMediaViewSize = 'full';
                break;
        }
    }

    const is = useCallback((...states) => states.some(state => state === callState), [callState]);
    const isNot = useCallback((...states) => !states.some(state => state === callState), [callState]);

    useEffect(() => {
        is('connected') && setCallTime(+new Date())
    }, [callState])

    useEffect(() => {
        setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
    });

    useEffect(() => {
        if (call?.callState === 'ended') {
            const time = +timeLeft.hours > 20 ? {hours: 0, minutes: 0, seconds: 0} : timeLeft
            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(JSON.stringify({action: "End Call", callTime: time}));
            }
            call.end()
            clearCalls()
        }
    }, [call])

    const timeView = (time: any) => time < 10 ? '0' + time : time
    return (
        <Wrapper>
            <Background>
                {
                    call.isVideoCall
                        ? (
                            <>
                                <VideoViewDiv size={remoteMediaViewSize}>
                                    <VideoView
                                        ref={remoteMediaViewRef}
                                        playsInline
                                        autoPlay
                                        muted={false}
                                        controls={isSafari()}
                                    />
                                </VideoViewDiv>
                                <VideoViewDiv size={localMediaViewSize}>
                                    <VideoView
                                        ref={localMediaViewRef}
                                        playsInline
                                        autoPlay
                                        muted
                                        style={{backgroundColor: 'var(--white)',
                                            opacity: !userId && !is('connected') ? 0.3 : 1}}
                                    />
                                </VideoViewDiv>
                            </>
                        )
                        : (
                            <>
                                <audio
                                    ref={localMediaViewRef}
                                    playsInline
                                    autoPlay
                                    muted
                                />
                                <audio
                                    ref={remoteMediaViewRef}
                                    playsInline
                                    autoPlay
                                    muted={false}
                                    controls={isSafari()}
                                />
                            </>
                        )
                }
            </Background>
            <Foreground>
                {(!userId && mobileWebViewStatus === 'full') && is('connected') &&
          <MobileBackDiv onClick={() => {
              if (window.ReactNativeWebView) {
                  window.ReactNativeWebView.postMessage(JSON.stringify({action: "Small Screen"}));
              }
              setMobileWebViewStatus('small')
          }}>
              <Logo
                  src='/icons/back-arrow.svg'
                  alt='arrow svg icon'
              />
              {(callTime > 1 && difference > 1000) && mobileWebViewStatus === 'full' && (
                  <CallerNameDiv>
                      <span style={{paddingRight: 10, paddingLeft: 5}}>
                          {call?.caller.nickname.split(" ")[0]}
                      </span>
                      <div>
                          <span>{timeView(timeLeft.minutes)}</span>
                          <span>:</span>
                          <span>{timeView(timeLeft.seconds)}</span>
                      </div>
                  </CallerNameDiv>
              )}
          </MobileBackDiv>
                }
                {mobileWebViewStatus === 'small' &&
          <ResizeButtonSmall onClick={() => {
              if (window.ReactNativeWebView) {
                  window.ReactNativeWebView.postMessage(JSON.stringify({action: "Medium Screen"}));
              }
              setMobileWebViewStatus('medium')
          }}/>}
                {mobileWebViewStatus === 'medium' &&
          <ResizeButtonMedium key='resize' onClick={() => {
              if (window.ReactNativeWebView) {
                  window.ReactNativeWebView.postMessage(JSON.stringify({action: "Full Screen"}));
              }
              setMobileWebViewStatus('full')
          }}/>}

                {mobileWebViewStatus !== 'small' &&
          <Controls>
              {is('connected') ?
                  <ButtonsContainerRing
                      style={{paddingBottom: 30, width: mobileWebViewStatus === 'medium' ? 200 : 350}}
                  >
                      {isNot('ended') && mobileWebViewStatus === 'full' && ([
                          isLocalVideoEnabled ?
                              <StopVideoButton key='stop-video' onClick={call.stopVideo}/>
                              : <StartVideoButton key='start-video' onClick={call.startVideo}/>,
                          isLocalAudioEnabled ?
                              <MuteButton key='mute-audio' onClick={call.muteMicrophone}/>
                              : <UnmuteButton key='unmute-audio' onClick={call.unmuteMicrophone}/>
                      ])}
                      {!userId && mobileWebViewStatus === 'full' && <ChatButton
                          onClick={() => {
                              if (window.ReactNativeWebView) {
                                  window.ReactNativeWebView.postMessage(JSON.stringify({action: "Small Screen"}));
                              }
                              setMobileWebViewStatus('small')
                          }}
                      />}
                  </ButtonsContainerRing>
                  : <ButtonsContainerRing style={{paddingBottom: 50}}>
                      {isNot('ended') && mobileWebViewStatus === 'full' &&([
                          isLocalVideoEnabled ?
                              <StopVideoButtonOpacity key='stop-opacity-video' onClick={call.stopVideo}/>
                              : <StartVideoButtonOpacity key='start-opacity-video' onClick={call.startVideo}/>,
                          isLocalAudioEnabled ?
                              <MuteButtonOpacity key='mute-opacity-audio' onClick={call.muteMicrophone}/>
                              : <UnmuteButtonOpacity key='unmute-opacity-audio' onClick={call.unmuteMicrophone}/>
                      ])}
                  </ButtonsContainerRing>}
              <ButtonsContainerRing style={{paddingBottom: is('connected') ? 0 : 60,
                  justifyContent: !userId && mobileWebViewStatus === 'medium' ? 'space-between' : 'space-around',
                  width: !userId && mobileWebViewStatus === 'medium' ? 250 : 350,
              }}>
                  {mobileWebViewStatus === 'medium' && ([
                      isLocalVideoEnabled ?
                          <StopVideoButton key='stop-video' onClick={call.stopVideo}/>
                          : <StartVideoButton key='start-video' onClick={call.startVideo}/>,
                      isLocalAudioEnabled ?
                          <MuteButton key='mute-audio' onClick={call.muteMicrophone}/>
                          : <UnmuteButton key='unmute-audio' onClick={call.unmuteMicrophone}/>
                  ])}
                  {
                      isNot('ended') &&
                <CallButtonsContainer>
                    <EndButton onClick={() => {
                        const time = +timeLeft.hours > 20 ? {hours: 0, minutes: 0, seconds: 0} : timeLeft
                        call.end()
                        clearCalls()
                        if (window.ReactNativeWebView) {
                            window.ReactNativeWebView.postMessage(JSON.stringify({action: "End Call", callTime: time}));
                        }
                        window.close()
                    }}
                    style={{marginBottom: !is('connected') ? 10 : 0}}
                    />

                    {!is('connected') && <ControlButtonText>Cancel</ControlButtonText>}
                </CallButtonsContainer>
                  }
                  {
                      is('ringing')
                && (call.isVideoCall
                    ? <CallButtonsContainer>
                        <VideoCallAcceptButton
                            style={{marginBottom: !is('connected') ? 10 : 0}}
                            onClick={accept}
                        />
                        <ControlButtonText style={{paddingLeft: 10}}>Accept</ControlButtonText>
                    </CallButtonsContainer>
                    :
                    <CallButtonsContainer>
                        <AudioCallAcceptButton onClick={accept}/>
                        <ControlButtonText>Accept</ControlButtonText>
                    </CallButtonsContainer>

                )
                  }
              </ButtonsContainerRing>
          </Controls>}
            </Foreground>
        </Wrapper>
    );
};

export default CallView;
