import styled from 'styled-components';

import ButtonCircle from 'components/atoms/ButtonCircle';

export const CallButton = styled(ButtonCircle)`
  padding: 28px;
  margin: 0 7px;
  background-repeat: no-repeat;
  background-position: center center;
`;

export const DialButton = styled(ButtonCircle)`
  padding: 28px;
  margin: 0 7px;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid var(--purple-300);
`;

export const OpacityButton = styled(ButtonCircle)`
  opacity: 0.6;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center center;
`;

export const VideoDialButton = styled(DialButton)`
  background-image: url(/icons/ic-callkit-start.svg);
`;

export const AudioDialButton = styled(DialButton)`
  background-color: var(--purple-300);
  background-image: url(/icons/ic-callkit-start.svg);
`;

export const VideoCallAcceptButton = styled(CallButton)`
  background-color: #2eba9f;
  //background-image: url(/icons/icon-call-video.svg);
  background-image: url(/icons/ic-callkit-start.svg);
  &:hover {
    background-color: #007a7a;
  }
`;

export const AudioCallAcceptButton = styled(CallButton)`
  background-color: #2eba9f;
  background-image: url(/icons/ic-callkit-start.svg);
  &:hover {
    background-color: #007a7a;
  }
`;

export const MuteButton = styled(CallButton)`
  background-color: var(--white);
  background-image: url(/icons/ic-callkit-audio-on-black.svg);

  &:hover {
    background-color: var(--white);
  }
`;

export const MuteButtonOpacity = styled(CallButton)`
  background-color: var(--white-opacity);
  background-image: url(/icons/ic-callkit-audio-on-black.svg);

  &:hover {
    background-color: var(--white-opacity-hover);
  }
`;

export const UnmuteButton = styled(CallButton)`
  background-color: var(--white);
  background-image: url(/icons/ic-callkit-audio-off-black.svg);
  &:hover {
    background-color: var(--white);
  }
`;

export const UnmuteButtonOpacity = styled(CallButton)`
  background-color: var(--white-opacity);
  background-image: url(/icons/ic-callkit-audio-off-black.svg);
  &:hover {
    background-color: var(--white-opacity-hover);
  }
`;

export const StopVideoButton = styled(CallButton)`
  background-color: var(--white);
  background-image: url(/icons/Cic-video-thumbnail-on-dark.svg);
  &:hover {
    background-color: var(--white);
  }
`;

export const StopVideoButtonOpacity = styled(CallButton)`
  background-color: var(--white-opacity);
  background-image: url(/icons/Cic-video-thumbnail-on-dark.svg);
  &:hover {
    background-color: var(--white-opacity-hover);
  }
`;

export const StartVideoButton = styled(CallButton)`
  background-color: var(--white);
  background-image: url(/icons/Cic-video-thumbnail-off-dark.svg);
  &:hover {
    background-color: var(--white);
  }
`;

export const StartVideoButtonOpacity = styled(CallButton)`
  background-color: var(--white-opacity);
  background-image: url(/icons/Cic-video-thumbnail-off-dark.svg);
  &:hover {
    background-color: var(--white-opacity);
  }
`;

export const EndButton = styled(CallButton)`
  margin-bottom: 5px;
  background-size: 32px;
  background-color: #e53157;
  background-image: url(/icons/ic-callkit-end.svg);
  &:hover {
    background-color: #a30e2d;
  }
`;

export const ChatButton = styled(DialButton)`
  background-color: var(--white);
  background-image: url(/icons/ic-callkit-chat.svg);
  &:hover {
    background-color: var(--white);
  }
`;

export const CameraControlButton = styled(CallButton)`
  background-color: var(--white-opacity);
  background-image: url(/icons/flip-camera.svg);
  &:hover {
    background-color: var(--white-opacity);
  }
`;

export const ResizeButtonSmall = styled(OpacityButton)`
  margin-bottom: -15px;
  margin-left: 100px;
  width: 40px;
  height: 40px;
  background-color: var(--white);
  background-image: url(/icons/resize.svg);
  &:hover {
    background-color: var(--white);
  }
`;

export const ResizeButtonMedium = styled(OpacityButton)`
  position: absolute;
  margin-bottom: 80%;
  background-color: var(--white);
  background-image: url(/icons/resize.svg);
  &:hover {
    background-color: var(--white);
  }
`;
