import ChoicesTemp from '../../templates/Choices';
import styled from 'styled-components';
import * as mixins from 'styles/mixins';
import React from 'react';
import {useEffect} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';

declare global {
    interface Window {
        ReactNativeWebView:any;
    }
}

const Wrapper = styled.div`
  ${mixins.fullScreen};
  ${mixins.bgWhite};
  padding: 33px;
  justify-content: center;
  align-items: center;
`;

const LandingPage = () => {
    const history = useHistory();
    const { url } = useRouteMatch();
    useEffect(() => {
        const matchRelativeUrl = url[url.length - 1] === '/' ? url.substr(0, url.length - 1) : url;
        history.push(`${matchRelativeUrl}/direct-call`)
    },[])
    return (
        <Wrapper>
            <ChoicesTemp
                title='Caraway Video Calls'
                desc='To start a call, return to your application.'
                choices={[{
                    to: 'direct-call',
                    title: 'Direct Call',
                    icon: '/icons/ic-direct-call-filled.svg',
                    desc: 'Using a web application, make 1-to-1 voice and video calls on a full-screen or a widget.'
                }, {
                    to: 'group-call',
                    title: 'Group Call',
                    icon: '/icons/ic-group-call-filled.svg',
                    desc: 'In Rooms, multiple users can come in to participate in group calls. '
                }]}
            />
        </Wrapper>
    );
}

export default LandingPage;
